import React from "react";
import loadable from "@loadable/component";

const PageContainer = loadable(() => import("@templates/DefaultPageContainer"));
const FullHero = loadable(() => import("@organisms/FullHero"));
const PageBuilder = loadable(() => import("@templates/PageBuilder"));

const HomePage = ({ data }) => {
  const { uid, hero, pageBuilder, meta } = data;
  return (
    <PageContainer meta={meta} path={uid}>
      <FullHero {...hero} />
      <PageBuilder blocks={pageBuilder} />
    </PageContainer>
  );
};

export default HomePage;
