import { buildCloudflareURL } from "@utils/imageUtils";

const resolveImages = images => {
  if (images && images.length > 0) {
    return images.map(image => {
      const { focalPoint, hasFocalPoint, url } = image;
      return {
        ...image,
        eagerUrl: buildCloudflareURL({ url, w: 360 }),
        focalPoint: hasFocalPoint ? focalPoint : false,
      };
    });
  }
  return null;
};

export default resolveImages;
